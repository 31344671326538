.lv-videos-app-header {
  width: 100%;
  padding: 13px 16px;
  background: var(--color-1);
  box-shadow: var(--shadow);
  box-sizing: border-box;

  .logo {
    display: inline-block;
    width: 277px;
    height: 26px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 639px) {
  .lv-videos-app-header {
    text-align: center;
  }
}