.videos-grid-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0px -8px;

  .video-grid-col {
    flex-basis: 25%;
    padding: 0 8px;
  }

  .video-grid-item {
    display: block;
    margin-bottom: 20px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;

    .thumbnail {
      position: relative;
      width: 100%;
      height: 140px;
      max-width: 224px;
      background-color: #f0f3f8;

      img {
        width: 100%;
        height: 100%;
      }

      .duration {
        position: absolute;
        bottom: 8px;
        right: 8px;
        min-width: 38px;
        padding: 4px;
        background-color: #3c3c3e;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .videos-grid-row {
    .video-grid-col {
      flex-basis: 33.3%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .videos-grid-row {
    .video-grid-col {
      flex-basis: 50%;
    }
  }
}

@media only screen and (max-width: 639px) {
  .videos-grid-row {
    .video-grid-col {
      flex-basis: 100%;
    }
  }
}