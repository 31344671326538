.video-page {
  .video-player-wrapper {
    position: relative;

    &::before {
      content: " ";
      width: 100%;
      top: 24px;
      position: absolute;
      height: 480px;
      background: var(--color-2);
      z-index: 0;
      height: calc(100% - 48px);
    }

    video {
      position: relative;
      width: 100%;
      z-index: 1;
    }
  }

  .back-button {
    a,
    a:hover {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 639px) {
  .video-page .lv-videos-container {
    max-width: 300px;
  }
}