@import '@fortawesome/fontawesome-pro/css/light.min';
@import '@fortawesome/fontawesome-pro/css/fontawesome.min';

body.lv-ds-beta.videos-app {
  background-color: var(--color-1);
  overflow-y: auto;
  min-width: 320px;
}

.video-date {
  display: flex;
  align-items: center;
  justify-self: flex-start;
}

.lv-videos-container {
  margin: 24px auto;
  font-family: 'Roboto', sans-serif;
  max-width: 946px;
}

@media only screen and (max-width: 1280px) {
  .lv-videos-container {
    max-width: 704px;
  }
}

@media only screen and (max-width: 1023px) {
  .lv-videos-container {
    max-width: 466px;
  }
}

@media only screen and (max-width: 639px) {
  .lv-videos-container {
    max-width: 224px;
  }
}